import React, { useState, useEffect, createContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
// import Tooltip from "../../components/Tooltip";
import EasyCrop from "../../components/EasyCrop";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useParams } from "react-router";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Calander from "../../img/calander.svg";
import RightArrow from "../../img/rightArrow.svg";
import SmallRightArrow from "../../img/smallRightArrow.svg";
import QuestionMark from "../../img/questionMark.svg";
import CloseModal from "../../img/closeModal.svg";
import Success from "../../img/Success.svg";
import CloseAlert from "../../img/CloseAlert.svg";
import ArrowDropdown from "../../img/arrowDropdown.svg";
import TextField from "@mui/material/TextField";
import Switch from "react-switch";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MyWorlds from "./index";

let upgradeLiteToPlanApi = [
  {
    user_id: 10,
    world_id: 339,
    mitopro_feature: 1,
    exp: 1675823422,
  },
];

const WorldDetail = ({ userData, jwt }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { worldDetails } = location.state;
  const [worldName, setWorldName] = useState(worldDetails.world_name ?? "");
  const [worldNameUrl, setWorldNameUrl] = useState(
    worldDetails.world_link
      ? worldDetails.world_link.replace(/\s+/g, "-").toLowerCase()
      : worldDetails.world_name.replace(/\s+/g, "-").toLowerCase()
  );

  const [worldDescription, setWorldDescription] = useState(
    worldDetails.world_description ?? ""
  );
  const [blueprint, setBlueprint] = useState(
    worldDetails.template_thumbnail ?? ""
  );
  const [tempBlueprint, setTempBlueprint] = useState("");
  const [blueprintId, setBlueprintId] = useState("");

  const [action, setAction] = useState("");

  const [trialPeriod, setTrialPeriod] = useState("31 days");
  const [worldStatus, setWorldStatus] = useState(worldDetails.world_status); //0 = unpublish, 1 = published,2 = viewonly
  const [requireSub, setRequireSub] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [notification, setNotification] = useState("");
  const [isCopied, setIsCopied] = useState(false); //Used for pop up

  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState();
  const [worldImage, setWorldImage] = useState(worldDetails.world_pic);
  const [showCropModal, setCropModal] = useState(false);
  const [templateId, setTemplateId] = useState(worldDetails.template_id);
  const [canUpdate, setCanUpdate] = useState(false);
  const [worldThumbnail, setWorldThumbnail] = useState();

  useEffect(() => {
    document.title = "Mitoworld - " + worldName;

    var today = moment().startOf("day");
    var subscription = moment(worldDetails.subscription_end, "YYYY-MM-DD");

    var period = moment.duration(subscription.diff(today)).asDays();

    setTrialPeriod(period + " days");

    if ((worldImage && worldImage !== "") || worldDetails.world_pic) {
      setWorldThumbnail(worldDetails.world_pic);
    } else {
      setWorldThumbnail(blueprint);
    }
  }, []);

  function SocialLinks() {
    const linkedin = "https://www.linkedin.com/sharing/share-offsite/?url=";
    const facebook = "https://www.facebook.com/sharer.php?u=";
    const twitter = "https://twitter.com/intent/tweet?url=";
    const whatsapp = "https://wa.me/?text=";
    const link =
      "https://mitoworld.io/world/w/" +
      worldDetails.world_id +
      "/" +
      worldNameUrl;
    const handleShare = (platformUrl) => {
      window.open(platformUrl + link, "_blank");
    };

    return (
      <div className="flex gap-2">
        <div
          className={`bg-[#F1F5F9] rounded-full p-3 text-[#155A9F] ${
            worldStatus == 1
              ? "cursor-pointer hover:bg-[#E2E8F0]"
              : "opacity-[0.3]"
          } `}
          onClick={() => handleShare(facebook)}
        >
          <Unicons.UilFacebook className="inline" />
        </div>
        <div
          className={`bg-[#F1F5F9] rounded-full p-3 text-[#155A9F] ${
            worldStatus == 1
              ? "cursor-pointer hover:bg-[#E2E8F0]"
              : "opacity-[0.3]"
          } `}
          onClick={() => handleShare(twitter)}
        >
          <Unicons.UilTwitter className="inline" />
        </div>
        <div
          className={`bg-[#F1F5F9] rounded-full p-3 text-[#155A9F] ${
            worldStatus == 1
              ? "cursor-pointer hover:bg-[#E2E8F0]"
              : "opacity-[0.3]"
          } `}
          onClick={() => handleShare(linkedin)}
        >
          <Unicons.UilLinkedin className="inline" />
        </div>
        <div
          className={`bg-[#F1F5F9] rounded-full p-3 text-[#155A9F] ${
            worldStatus == 1
              ? "cursor-pointer hover:bg-[#E2E8F0]"
              : "opacity-[0.3]"
          } `}
          onClick={() => handleShare(whatsapp)}
        >
          <Unicons.UilWhatsappAlt className="inline" />
        </div>
      </div>
    );
  }

  function handleInput(event) {
    switch (event.target.name) {
      case "WorldName":
        setWorldName(event.target.value);
        break;
      case "WorldDescription":
        setWorldDescription(event.target.value);
        break;
      case "WorldNameUrl":
        let myString = event.target.value;
        myString = myString.replace(/\s+/g, "-");
        // worldDetails.worldName
        setWorldNameUrl(myString.toLowerCase());
        break;
      default:
        break;
    }
    setCanUpdate(true);
  }

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (copyText) => {
    let world_link =
      "https://mitoworld.io/world/w/" + worldDetails.world_id + "/" + copyText;
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(world_link)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setNotification("Link copied.");
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function changeBlueprintModal() {
    setShowModal(!showModal);
  }

  function changeBlueprintDetailModal() {
    setShowDetailsModal(!showDetailsModal);
  }

  function changeConfirmationModal(action) {
    setAction(action ?? "");
    setShowConfirmationModal(!showConfirmationModal);
  }

  function selectBlueprint(blueprintImg, id) {
    setTempBlueprint(blueprintImg);
    setBlueprintId(id);
    changeBlueprintDetailModal();
  }

  async function applyBlueprint(blueprintImg, worldId, templateId) {
    var exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("template_id", templateId);
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setBlueprint(blueprintImg);
        changeBlueprintModal();
        setNotification(
          "You have successfully applied blueprint. Please proceed to edit and decorate your world."
        );
        setTemplateId(worldDetails.template_id);
        changeBlueprintDetailModal();
        setCanUpdate(true);
        setWorldThumbnail(blueprintImg);
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  function removeBlueprint() {
    changeBlueprintDetailModal();
    changeConfirmationModal("unlink");
  }

  async function unlinkBlueprint(worldId) {
    var exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("template_id", 0);
    body.append("world_status", 0);
    body.append("world_pic", "");
    body.append("map_json", '{"Items":{},"Theme":"","IsFirstTime":true}');
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setBlueprint("");
        setTemplateId("0");
        setWorldStatus(0);
        setWorldImage("");
        setNotification("You have successfully unlink the blueprint.");
        changeConfirmationModal();
        setCanUpdate(true);
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  async function saveWorld(worldId) {
    var exp = Math.floor(new Date().getTime() + 6000 / 1000);
    let mainImageUrl = "";

    if (croppedImage) {
      let response = await fetch(croppedImage);
      console.log("response", response);
      let data = await response.blob();
      console.log("data", data);
      let metadata = {
        type: "image/jpeg",
      };
      let cropImageBody = new FormData();
      cropImageBody.append("world_id", worldId);

      let file = new File([data], "cropped.jpg", metadata);
      cropImageBody.append("main_image", file);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/ajax/v3/ajax_thumbnail_upload_staging.php`,
          cropImageBody,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          mainImageUrl = response.data.url;
        })
        .catch((err) => {
          console.error("err", err);
          setNotification("An error occured.");
        });
    }

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("world_name", worldName);
    body.append("world_description", worldDescription);
    body.append("world_link", worldNameUrl);
    if (croppedImage) {
      body.append("world_pic", mainImageUrl);
    }
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setWorldImage(mainImageUrl ? mainImageUrl : worldDetails.world_pic);

        setNotification(`You have successfully save the ${worldName} World.`);
        console.log("Save world");
      })
      .catch((err) => {
        console.error("err", err);
        // setNotification("You didn't have any changes");
        setNotification("An error occured.");
      });
    navigate("/worlds");
  }

  async function publishWorld(worldId) {
    var exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("world_status", 1);
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setWorldStatus(1);
        setNotification(
          `You have successfully published the ${worldName} World.`
        );
        console.log("Publishing world");
        changeConfirmationModal();
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  async function unpublishWorld(worldId) {
    var exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("world_status", 0);
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setWorldStatus(0);
        changeConfirmationModal();
        setNotification(
          `You have successfully unpublished the ${worldName} World.`
        );
        console.log("Unpublishing world");
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  async function deleteWorld(worldId) {
    console.log("Deleting world");

    var exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    body.append("user_id", userData.userId);
    body.append("world_id", worldId);
    body.append("is_active", 0);
    body.append("exp", exp);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        navigate("/worlds", {
          state: {
            message: "You have successfully deleted a MitoPro World.",
          },
        });
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  function changeCropModal() {
    setCropModal(!showCropModal);
  }

  async function resetCropImage(worldId) {
    if (worldImage && worldImage !== "") {
      var exp = Math.floor(new Date().getTime() + 6000 / 1000);

      let body = new FormData();
      body.append("user_id", userData.userId);
      body.append("world_id", worldId);
      body.append("world_pic", "");
      body.append("exp", exp);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/world-data/update-world`,
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          setCroppedImage(null);
          setWorldImage(null);
          setWorldThumbnail(blueprint);
          changeConfirmationModal();
        })
        .catch((err) => {
          console.error("err", err);
          setNotification("An error occured.");
        });
    } else {
      setCroppedImage(null);
      setWorldImage(null);
      setWorldThumbnail(blueprint);
      changeConfirmationModal();
    }
  }

  const handleImageUpload = async (e) => {
    changeCropModal();
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  ////////////////////////////////////////////////////////////////////////////////////
  // Handling Gating Pop Up Window ///////////////////////////////////////////////////

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDateAcessOpen, setIsDateAccessOpen] = useState(false);
  const [isPasscodeOpen, setIsPasscodeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [enableDateAccess, setEnableDateAccess] = useState(false);
  const [enablePasscodeAccess, setEnablePasscodeAccess] = useState(false);
  const [isClickPasscode, setIsClickPasscode] = useState(false);
  const [isClickNFT, setIsClickNFT] = useState(false);
  const [GatingDateAccessStartDate, setGatingDateAccessStartDate] =
    useState(null);
  const [GatingDateAccessEndDate, setGatingDateAccessEndDate] = useState(null);
  const [GatingDateAccessRegion, setGatingDateAccessRegion] = useState("");
  const [isDisabledDateAccess, setIsDisabledDateAccess] = useState(true);
  const [GatingPasscode, setGatingPasscode] = useState("");
  const [isDisabledPasscode, setIsDisabledPasscode] = useState(true);
  const [GatingBlockchain, setGatingBlockchain] = useState("ETHEREUM");
  const [GatingContract, setGatingContract] = useState("ERC-721");
  const [GatingContractAddress, setGatingContractAddress] = useState("");
  const [GatingMinimumQuantity, setGatingMinimumQuantity] = useState("");
  const [isDateAccessActive, setIsDateAccessActive] = useState(false);
  const [isPasscodeActive, setIsPasscodeActive] = useState(false);
  const [attemptClose, setAttemptClose] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [isDateAccessUpdate, setIsDateAccessUpdate] = useState(false);
  const [isPasscodeUpdate, setIsPasscodeUpdate] = useState(false);
  const [toggleGatingAlert, setToggleGatingAlert] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isValidPasscode, setIsValidPasscode] = useState(true);
  const [savedStartDate, setSavedStartDate] = useState(null);
  const [savedEndDate, setSavedEndDate] = useState(null);
  const [savedRegion, setSavedRegion] = useState("");
  const [savedPasscode, setSavedPasscode] = useState("");
  const [savedBlockchain, setSavedBlockchain] = useState("ETHEREUM");
  const [savedContract, setSavedContract] = useState("ERC-721");
  const [savedContractAddress, setSavedContractAddress] = useState("");
  const [savedMinimumQuantity, setSavedMinimumQuantity] = useState("");

  const handleGatingPopupOpen = () => {
    setIsPopupOpen(true);
    setIsDateAccessOpen(false);
    setIsPasscodeOpen(false);
  };

  const handleGatingPopupClose = () => {
    if (!isProgress) {
      handleLeave();
    } else {
      setAttemptClose(true);
    }
  };

  const handleLeave = () => {
    setIsPopupOpen(false);
    setIsDateAccessOpen(false);
    setIsPasscodeOpen(false);
    setIsClickPasscode(false);
    setIsClickNFT(false);
    setGatingDateAccessRegion("");
    setGatingDateAccessStartDate(null);
    setGatingDateAccessEndDate(null);
    setGatingPasscode("");
    setGatingBlockchain("ETHEREUM");
    setGatingContract("ERC-721");
    setGatingContractAddress("");
    setGatingMinimumQuantity("");
    setAttemptClose(false);
    setIsProgress(false);
    isDateAccessActive ? setEnableDateAccess(true) : setEnableDateAccess(false);
    isPasscodeActive
      ? setEnablePasscodeAccess(true)
      : setEnablePasscodeAccess(false);
  };

  const handleStay = () => {
    setAttemptClose(false);
  };

  const triggerSnackbar = (m) => {
    setSnackbarMessage(m);
    setIsSnackbarOpen(true);
    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 3000);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleDateAccessOpen = () => {
    setIsDateAccessOpen(true);
  };

  const handleDateAccessClose = () => {
    setIsDateAccessOpen(false);
    setIsPopupOpen(true);
    setGatingDateAccessRegion("");
    setGatingDateAccessStartDate(null);
    setGatingDateAccessEndDate(null);

    if (!isDateAccessActive) {
      setEnableDateAccess(false);
    }
  };

  const handlePasscodeOpen = () => {
    setIsPasscodeOpen(true);
  };

  const handlePasscodeClose = () => {
    setIsPasscodeOpen(false);
    setIsPopupOpen(true);
    setGatingPasscode("");
    setIsClickPasscode(false);
    setIsClickNFT(false);
    setGatingBlockchain("ETHEREUM");
    setGatingContract("ERC-721");
    setGatingContractAddress("");
    setGatingMinimumQuantity("");

    if (!isPasscodeActive) {
      setEnablePasscodeAccess(false);
    }
  };

  const toggleDateAccess = () => {
    if (enableDateAccess && isDateAccessActive) {
      setToggleGatingAlert(true);
    } else if (enableDateAccess && !isDateAccessActive) {
      setEnableDateAccess(false);
    } else {
      setEnableDateAccess(true);
    }
  };

  const togglePasscodeAccess = () => {
    if (enablePasscodeAccess && isPasscodeActive) {
      setToggleGatingAlert(true);
    } else if (enablePasscodeAccess && !isPasscodeActive) {
      setEnablePasscodeAccess(false);
    } else {
      setEnablePasscodeAccess(true);
    }
  };

  const handleConfirmDisable = () => {
    if (isDateAcessOpen && enableDateAccess) {
      setEnableDateAccess(false);
      setIsDateAccessActive(false);
      setGatingDateAccessRegion("");
      setGatingDateAccessStartDate(null);
      setGatingDateAccessEndDate(null);
      setSavedRegion("");
      setSavedStartDate(null);
      setSavedEndDate(null);
      saveDateAccess();
    } else if (isPasscodeOpen && enablePasscodeAccess) {
      setEnablePasscodeAccess(false);
      setIsClickNFT(false);
      setIsClickPasscode(false);
      setIsPasscodeActive(false);
      setGatingPasscode("");
      setGatingBlockchain("ETHEREUM");
      setGatingContract("ERC-721");
      setGatingContractAddress("");
      setGatingMinimumQuantity("");
      setSavedBlockchain("ETHEREUM");
      setSavedContract("ERC-721");
      setSavedContractAddress("");
      setSavedMinimumQuantity("");
      setSavedPasscode("");
      savePasscode();
    }
    triggerSnackbar("You have successfully disabled the gating access.");
    setToggleGatingAlert(false);
  };

  const handleCancelDisable = () => {
    setToggleGatingAlert(false);
  };

  const handleClickPasscode = () => {
    if (isClickPasscode) {
      setIsClickPasscode(false);
    } else {
      setIsClickPasscode(true);
      setIsClickNFT(false);
      setGatingBlockchain("ETHEREUM");
      setGatingContract("ERC-721");
      setGatingContractAddress("");
      setGatingMinimumQuantity("");
    }
  };
  const handleClickNFT = () => {
    if (isClickNFT) {
      setIsClickNFT(false);
    } else {
      setIsClickNFT(true);
      setIsClickPasscode(false);
      setGatingPasscode("");
    }
  };

  const handleGatingDateAccessRegion = (e) => {
    setGatingDateAccessRegion(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setGatingDateAccessStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setGatingDateAccessEndDate(date);
  };

  const handlePasscodeChange = (e) => {
    setGatingPasscode(e.target.value);
  };

  const handleBlockchainChange = (e) => {
    setGatingBlockchain(e.target.value);
  };

  const handleContractAddress = (e) => {
    setGatingContractAddress(e.target.value);
  };

  const handleContractChange = (e) => {
    setGatingContract(e.target.value);
  };

  const handleMinimumQuantity = (e) => {
    setGatingMinimumQuantity(e.target.value);
  };

  const saveDateAccess = () => {
    setSavedStartDate(GatingDateAccessStartDate);
    setSavedEndDate(GatingDateAccessEndDate);
    setSavedRegion(GatingDateAccessRegion);
  };

  const activateDateAccess = () => {
    setIsDateAccessActive(true);
    saveDateAccess();
  };

  const updateDateAccess = () => {
    triggerSnackbar("You have successfully updated the gating access.");
    saveDateAccess();
  };

  const closeUpdateDateAccess = () => {
    setIsDateAccessUpdate(false);
  };

  const closeUpdatePasscode = () => {
    setIsPasscodeUpdate(false);
  };

  const savePasscode = () => {
    setSavedPasscode(GatingPasscode);
    setSavedBlockchain(GatingBlockchain);
    setSavedContract(GatingContract);
    setSavedContractAddress(GatingContractAddress);
    setSavedMinimumQuantity(GatingMinimumQuantity);
  };

  const activatePasscode = () => {
    setIsPasscodeActive(true);
    savePasscode();
  };

  const updatePasscode = () => {
    triggerSnackbar("You have successfully updated the gating access.");
    savePasscode();
  };

  function DatetimePickerIcon() {
    return <img src={Calander} alt="Date picker opening icon" />;
  }

  function RightArrowIcon() {
    return <img src={RightArrow} alt="Right arrow icon" />;
  }

  function SmallRightArrowIcon() {
    return <img src={SmallRightArrow} alt="Small right arrow icon" />;
  }

  function CloseModalIcon() {
    return (
      <img src={CloseModal} alt="Close modal icon" className="h-auto w-auto" />
    );
  }

  function QuestionMarkIcon() {
    return (
      <img
        src={QuestionMark}
        alt="Question mark icon"
        className="hover:cursor-pointer"
      />
    );
  }

  function SuccessIcon() {
    return <img src={Success} alt="Success icon" />;
  }

  function CloseAlertIcon() {
    return <img src={CloseAlert} alt="Close Alert icon" />;
  }

  function ArrowDropdownIcon(props) {
    return (
      <img
        src={ArrowDropdown}
        alt="Arrow Dropdown icon"
        className={`${props.className}`}
      />
    );
  }

  const [GatingData, setGatingData] = useState({});
  const saveData = () => {
    let worldId = worldDetails.world_id;
    let editData = GatingData;
    editData[worldId] = {
      dateAccessRegion: savedRegion,
      startDate: savedStartDate,
      endDate: savedEndDate,
      passcodeActive: isPasscodeActive,
    };
    setGatingData(editData);
    sessionStorage.setItem("GatingData", JSON.stringify(GatingData));
    console.log(sessionStorage.getItem("GatingData"));
  };

  useEffect(() => {
    // used to disable Date Access button if any input field is empty
    setIsDisabledDateAccess(
      !(
        GatingDateAccessRegion !== "" &&
        GatingDateAccessStartDate !== null &&
        GatingDateAccessEndDate !== null
      )
    );

    // used to disable Passcode button if any input field is empty
    setIsDisabledPasscode(
      !(
        (isValidPasscode && GatingPasscode.length != 0) ||
        (GatingBlockchain !== "" &&
          GatingContract !== "" &&
          GatingContractAddress !== "" &&
          GatingMinimumQuantity !== "")
      )
    );

    // used to Validate Passcode input field
    setIsValidPasscode(
      GatingPasscode == "" ||
        (GatingPasscode.length >= 6 &&
          GatingPasscode.length <= 9 &&
          GatingPasscode.match(/^[a-zA-Z0-9]+$/) != null)
    );

    // used to prompt the user to confirm close the gating modal if they provided any information
    setIsProgress(
      GatingDateAccessRegion != "" ||
        GatingDateAccessStartDate != null ||
        GatingDateAccessEndDate != null ||
        GatingPasscode != "" ||
        GatingBlockchain != "ETHEREUM" ||
        GatingContract != "ERC-721" ||
        GatingContractAddress != "" ||
        GatingMinimumQuantity != ""
    );

    saveData();

  });


  ////////////////////////////////////////////////////////////////////////////////////

  return (
    <Layout
      pageTitle="World details"
      navigateBack={true}
      handleNotification={notification}
      userData={userData}
    >
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-col rounded-2xl pt-10">
          {/* About World & Blueprint */}
          <div className="flex justify-between gap-10 px-10 pb-6 border-b-2 relative">
            {/* About World */}
            <div className="flex flex-col basis-1/2 justify-start text-left">
              <p className="text-lg mb-2 font-medium">About your World</p>
              <p className="text-[#334155]">
                Fill details to make it public in Mitoworld discovery.
              </p>

              <div className="flex flex-row justify-between mt-8">
                <label
                  className="mb-2 text-left text-[#0F172A]"
                  htmlFor="WorldName"
                >
                  World Name
                </label>
                <label
                  className="mb-2 text-left text-[#94A3B8]"
                  htmlFor="WorldName"
                >
                  {worldName.length}/30 Characters
                </label>
              </div>
              <input
                name="WorldName"
                type="text"
                className="py-4 border rounded-[16px] px-8 mb-6 font-medium"
                placeholder="Forrest Land"
                maxLength="30"
                value={worldName}
                onChange={handleInput}
              />

              <div className="flex flex-row justify-between">
                <label
                  className="mb-2 text-left text-[#0F172A]"
                  htmlFor="WorldDescription"
                >
                  Description
                </label>
                <label
                  className="mb-2 text-left text-[#94A3B8]"
                  htmlFor="WorldDescription"
                >
                  {worldDescription.length}/120 Characters
                </label>
              </div>
              <textarea
                name="WorldDescription"
                type="text"
                className="py-4 border rounded-[16px] px-8 mb-6"
                placeholder="Describe your world..."
                onChange={handleInput}
                value={worldDescription}
                rows="4"
                maxLength="120"
              />
            </div>
            {/* Blueprint */}
            <div className="flex flex-col basis-1/2 justify-start text-left">
              <p className="text-lg mb-2 font-medium">Blueprint</p>
              <p className="text-[#334155]">
                Choose and apply blueprint to your World.{" "}
              </p>
              <div className="flex flex-row mt-8 items-center gap-8">
                {blueprint ? (
                  <>
                    <div className="flex items-center p-[16px] transition ease-in-out duration-300 rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 mb-4 overflow-hidden">
                      <img
                        onClick={() => changeBlueprintDetailModal()}
                        src={blueprint}
                        className="w-[210px] object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="font-medium">
                        {worldDetails.template_name}
                      </p>
                      <p className="text-[#94A3B8]">
                        by {worldDetails.creator_name}
                      </p>
                      <div
                        className="flex flex-row mt-2 bg-[#F8FAFC] border-2 border-[#E2E8F0] px-5 py-3 items-center gap-2 rounded-lg  hover:cursor-pointer hover:bg-[#F1F5F9]"
                        onClick={() =>
                          window.open(worldDetails.link_demo, "_blank")
                        }
                      >
                        <img
                          src={require("../../img/visit_edit_world_button.png")}
                          className="width-[36px] object-cover"
                          alt=""
                        />
                        {worldStatus == 1 ? (
                          <p className="text-sm text-[#155A9F] font-medium">
                            Edit The World
                          </p>
                        ) : (
                          <p className="text-sm text-[#155A9F] font-medium">
                            Visit & Edit World
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {templateId !== "0" ? (
                      <>
                        <div className="flex items-center p-[16px] transition ease-in-out duration-300 rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 mb-4 overflow-hidden">
                          <img
                            onClick={() => changeBlueprintDetailModal()}
                            src={worldDetails.template_thumbnail}
                            className="w-[210px] object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium">
                            {worldDetails.template_name}
                          </p>
                          <p className="text-[#94A3B8]">
                            by {worldDetails.creator_name}
                          </p>
                          <div
                            className="flex flex-row mt-2 bg-[#F8FAFC] border-2 border-[#E2E8F0] px-5 py-3 items-center gap-2 rounded-lg  hover:cursor-pointer hover:bg-[#F1F5F9]"
                            onClick={() =>
                              (window.location = worldDetails.link_demo)
                            }
                          >
                            <img
                              src={require("../../img/visit_edit_world_button.png")}
                              className="width-[36px] object-cover"
                              alt=""
                            />
                            <p className="text-sm">Visit & Edit World</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center p-[16px] transition ease-in-out duration-300 rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 mb-4 overflow-hidden">
                        <img
                          onClick={() => changeBlueprintModal()}
                          src={require("../../img/add_blueprint_button.png")}
                          className="w-[210px] object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                          alt=""
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {worldStatus == 2 && (
              <div className="absolute w-full h-full left-0 top-0 bg-white/[.7]"></div>
            )}
          </div>

          {/* Thumbnail & URL */}
          <div className="flex justify-between gap-10 px-10">
            {/* Thumbnail */}
            <div className="flex basis-1/3 flex-col text-left pt-6 pb-16 pr-5 border-r-2 relative">
              <div className="flex flex-row justify-between items-center">
                <p className="text-lg mb-2 font-medium">Thumbnail preview</p>
                {croppedImage || (worldImage && worldImage !== "") ? (
                  <p
                    onClick={() => changeConfirmationModal("reset_thumbnail")}
                    className="pl-2 text-sm mb-2 text-[#155A9F] hover:cursor-pointer"
                    id="reset_thumbnail_text"
                  >
                    Reset thumbnail
                  </p>
                ) : (
                  <p
                    className="pl-2 text-sm mb-2 text-[#155A9F] hover:cursor-not-allowed opacity-[0.3]"
                    id="reset_thumbnail_clicked"
                  >
                    Reset thumbnail
                  </p>
                )}
              </div>
              <p className="text-[#334155]">
                Select a thumbnail visual for your World.
              </p>
              <div className="block text-center mt-8">
                {blueprint || templateId !== "0" ? (
                  <input
                    type="file"
                    accept="image/*"
                    id="actual-btn"
                    name="cover"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                ) : (
                  ""
                )}

                <label htmlFor="actual-btn">
                  <div
                    className={`flex items-center h-40 w-40 p-[16px] rounded-full bg-[#F1F5F9] transition ease-in-out duration-300 mb-4 overflow-hidden  ${
                      blueprint || templateId !== "0"
                        ? "hover:bg-[#E2E8F0]"
                        : ""
                    }`}
                  >
                    <img
                      src={worldThumbnail}
                      className={`object-center mx-auto object-cover rounded-full bg-[#F1F5F9] transition ease-in-out duration-300 ${
                        blueprint || templateId !== "0"
                          ? "hover:cursor-pointer hover:bg-[#E2E8F0]"
                          : ""
                      }`}
                      alt=""
                    />
                  </div>

                  <p
                    className={`w-40 font-medium text-center text-[#0F172A] ${
                      blueprint || templateId !== "0" ? "" : "opacity-[0.3]"
                    }`}
                  >
                    Thumbnail
                  </p>
                </label>
              </div>

              {worldStatus == 2 && (
                <div className="absolute w-full h-full left-0 top-0 bg-white/[.7]"></div>
              )}
            </div>

            {/* URL */}
            <div className="flex basis-2/3 flex-col">
              <div className="flex self-end mb-3.5 -mr-10">
                {worldStatus == 0 ? (
                  <p className="px-11 py-3 font-medium text-center rounded-bl-[30px] bg-[#E2E8F0] text-sm">
                    Draft
                  </p>
                ) : worldStatus == 1 ? (
                  <p className="px-11 py-3 font-medium text-center rounded-bl-[30px] bg-[#10B981] text-[#FFFFFF] text-sm">
                    Published
                  </p>
                ) : (
                  worldStatus == 2 && (
                    <p className="px-11 py-3 font-medium text-center rounded-bl-[30px] bg-[#F5841F] text-[#FFFFFF] text-sm">
                      View-only
                    </p>
                  )
                )}
              </div>

              <div className="flex flex-row mb-4 relative">
                <label className="py-4 border rounded-[8px] w-full px-8 z-20 bg-[#F8FAFC] border-[#CBD5E1] text-[#94A3B8]">
                  https://mitoworld.io/world/w/{worldDetails.world_id}/
                </label>
                <input
                  name="WorldNameUrl"
                  type="text"
                  className="bg-white -ml-10 pl-14 w-full px-8 py-4 rounded-[8px] border border-[#CBD5E1]"
                  placeholder="forrest-land"
                  value={worldNameUrl}
                  onChange={handleInput}
                />
                {worldStatus == 1 ? (
                  <>
                    <button
                      className="absolute right-[0px] top-[0px] hover:bg-[#F1F5F9] rounded-full flex items-center justify-center w-[58px] h-[58px]"
                      onClick={() => handleCopyClick(worldNameUrl)}
                    >
                      {/* <span className="text-black"></span> */}
                      <span className="text-[#155A9F] ">
                        <Unicons.UilCopy />
                      </span>
                    </button>
                  </>
                ) : (
                  <>
                    <button className="absolute right-[0px] top-[0px] cursor-auto flex items-center justify-center w-[58px] h-[58px]">
                      {/* <span className="text-black"></span> */}
                      <span className="text-[#155A9F] opacity-[0.3] ">
                        <Unicons.UilCopy />
                      </span>
                    </button>
                  </>
                )}
              </div>

              <div className="grid grid-cols-3 divide-x border-2 mb-4 rounded-lg">
                <div className="p-4 text-left">
                  <p className="mb-2">World Type</p>
                  {worldStatus == 2 ? (
                    <>
                      <p className="mb-12 font-medium">MitoPro • View-only</p>

                      <Link to="/subscription">
                        <p className="text-[#155A9F] text-sm font-medium">
                          Manage Subscription
                        </p>
                      </Link>
                    </>
                  ) : worldDetails.mitopro_feature == 0 ? (
                    <>
                      <p
                        id="manage_subscription_link"
                        className="font-medium mb-12"
                      >
                        MitoLite
                      </p>
                      <button
                        className={`transition-all px-4 py-1 rounded-[69px] bg-[#155A9F] hover:bg-hover-primary-btn transition ease-in-out duration-300 block`}
                        onClick={() =>
                          navigate("/worlds/upgrade", {
                            state: {
                              worldId: worldDetails.world_id,
                            },
                          })
                        }
                      >
                        <span
                          style={{ color: "white" }}
                          className="text-sm flex"
                        >
                          Upgrade to MitoPro
                        </span>
                      </button>
                    </>
                  ) : worldDetails.mitopro_feature == 1 &&
                    worldDetails.subscription_type !== "trial" ? (
                    <>
                      <div className="mb-12 flex flex-row gap-1">
                        <p className="font-medium">MitoPro</p>
                        <img
                          src={require("../../img/verified.png")}
                          className="object-contain"
                          alt=""
                        />
                      </div>
                      <Link to="/subscription">
                        <p
                          id="manage_subscription_link"
                          className="text-[#155A9F] text-sm font-medium"
                        >
                          Manage Subscription
                        </p>
                      </Link>
                    </>
                  ) : (
                    worldDetails.mitopro_feature == 1 &&
                    worldDetails.subscription_type == "trial" && (
                      <>
                        <p className="mb-1 font-medium">MitoPro • Free Trial</p>
                        <p className="mb-11 font-medium text-[#F5841F]">
                          {trialPeriod} left
                        </p>
                        <button
                          className={`transition-all px-4 py-1 rounded-[69px] bg-[#155A9F] hover:bg-hover-primary-btn transition ease-in-out duration-300 block mx-auto`}
                          onClick={() =>
                            navigate("/worlds/upgrade", {
                              state: {
                                worldId: worldDetails.world_id,
                              },
                            })
                          }
                        >
                          <span style={{ color: "white" }} className="text-sm">
                            Upgrade to MitoPro
                          </span>
                        </button>
                      </>
                    )
                  )}
                </div>
                <div className="col-span-2 grid grid-rows-3 divide-y">
                  <div className="flex flex-row items-center justify-between px-3.5 relative">
                    <div
                      className="flex flex-row items-center gap-1.5"
                      data-tooltip-place="right"
                      data-tooltip-id="token_gating"
                      data-tooltip-html="<p style='margin-bottom: 10px'>Gating Access</p> <p>Set up passcode or date/ <br />time controlled gating <br />access to your World <br />experience.</p>"
                    >
                      <p>Token Gating</p>
                      <div className="bg-[#F1F5F9] hover:cursor-pointer rounded-full h-[18px] w-[18px] flex items-center justify-center ">
                        <p className="text-[#94A3B8] font-medium text-sm">?</p>
                      </div>
                    </div>
                    <Tooltip
                      id="token_gating"
                      className="z-[999]"
                      style={{
                        backgroundColor: "#0F172A",
                        paddingBlock: 20,
                        paddingInline: 30,
                        borderRadius: 15,
                      }}
                    />
                    {/* -------------------------------------------------------------- */}
                    {isDateAccessActive || isPasscodeActive ? (
                      <div className="text-white text-center bg-[#10B981] rounded-[4px] w-[66px] h-[26px] hover:cursor-default">
                        Active
                      </div>
                    ) : (
                      <button
                        className="text-[#155A9F] flex justify-content items-center text-lg"
                        onClick={handleGatingPopupOpen}
                      >
                        Set Up <SmallRightArrowIcon />
                      </button>
                    )}

                    {isPopupOpen ? (
                      <>
                        <div className="fixed inset-0 z-40 outline-none focus:outline-none bg-black bg-opacity-50 flex justify-center h-auto">
                          <div className="flex flex-row mt-[2%] h-auto">
                            <div className="justify-center rounded-[24px] ml-[56px] w-[560px] mt-[40px] max-h-[70vh]">
                              <div className="bg-[#E2E8F0] mx-auto rounded-tl-[24px] rounded-tr-[24px] items-center px-[10%] h-[88px] flex top-[20px]">
                                {!(isDateAcessOpen || isPasscodeOpen) ? (
                                  <p className="my-auto text-2xl font-semibold hover:cursor-default">
                                    Gating Access Settings
                                  </p>
                                ) : (
                                  <>
                                    <Unicons.UilAngleLeftB
                                      color="#155A9F"
                                      size={40}
                                      className="hover:cursor-pointer"
                                      viewBox="7 0 24 24"
                                      onClick={
                                        isDateAcessOpen
                                          ? handleDateAccessClose
                                          : handlePasscodeClose
                                      }
                                    />
                                    <div className="my-auto ml-[3%] flex flex-row justify-between items-center w-full">
                                      <p className="text-2xl font-semibold hover:cursor-default">
                                        {isDateAcessOpen
                                          ? "Date Access"
                                          : "Passcode or NFT Token"}
                                      </p>
                                      <Switch
                                        onChange={
                                          isDateAcessOpen
                                            ? toggleDateAccess
                                            : togglePasscodeAccess
                                        }
                                        checked={
                                          isDateAcessOpen && enableDateAccess
                                            ? true
                                            : !isDateAcessOpen &&
                                              enablePasscodeAccess
                                            ? true
                                            : false
                                        }
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onHandleColor="#FFFFF"
                                        onColor="#155A9F"
                                        handleDiameter={20}
                                        className="h-auto"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>

                              {
                                // Gating Settings Selection
                                !(isDateAcessOpen || isPasscodeOpen) ? (
                                  <>
                                    <div className="bg-white px-[10%] pt-4 pb-4 flex items-center justify-between">
                                      <div className="w-[85%]">
                                        <div className="flex flex-row">
                                          <p className="text-lg font-semibold hover:cursor-default mr-[5%]">
                                            Date Access
                                          </p>
                                          {isDateAccessActive ? (
                                            <div className="bg-[#10B981] text-white rounded-[4px] text-sm flex items-center px-[5%]">
                                              Active
                                            </div>
                                          ) : null}
                                        </div>

                                        <p className="text-md w-[82%] text-[#334155] hover:cursor-default">
                                          Enable for time-specific events to
                                          control event access. (Concerts,
                                          parties, promotions etc)
                                        </p>
                                      </div>
                                      <span
                                        className="hover:cursor-pointer"
                                        onClick={handleDateAccessOpen}
                                      >
                                        <RightArrowIcon />
                                      </span>
                                    </div>

                                    <div className="bg-white px-[10%] pt-4 pb-4 rounded-bl-[24px] rounded-br-[24px] flex items-center justify-between">
                                      <div className="w-[85%]">
                                        <div className="flex flex-row">
                                          <p className="text-lg font-semibold hover:cursor-default mr-[5%]">
                                            Passcode or NFT token
                                          </p>
                                          {isPasscodeActive ? (
                                            <div className="bg-[#10B981] text-white rounded-[4px] text-sm flex items-center px-[5%]">
                                              Active
                                            </div>
                                          ) : null}
                                        </div>
                                        <p className="text-md w-[82%] text-[#334155] hover:cursor-default">
                                          Enable this to restrict access via a
                                          password or NFT token
                                        </p>
                                      </div>
                                      <span
                                        className="hover:cursor-pointer"
                                        onClick={handlePasscodeOpen}
                                      >
                                        <RightArrowIcon />
                                      </span>
                                    </div>
                                  </>
                                ) : // Date Access
                                isDateAcessOpen ? (
                                  <>
                                    <div className="bg-white px-[10%] rounded-bl-[24px] rounded-br-[24px] z-1 max-h-[70vh] relative overflow-auto">
                                      <div className="py-[3%]">
                                        <p className="text-lg mb-[2%]">
                                          Region
                                        </p>
                                        <TextField
                                          label="Select your region"
                                          onChange={
                                            handleGatingDateAccessRegion
                                          }
                                          value={GatingDateAccessRegion}
                                          variant="outlined"
                                          fullWidth
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              borderRadius: "43px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                              padding: "0px 20px 0px 20px",
                                            },
                                          }}
                                        />
                                      </div>
                                      <div className="flex flex-wrap justify-content pb-[4%] ">
                                        <div className="w-[50%] pr-2">
                                          <p className="text-lg mb-[2.5%]">
                                            Start Date
                                          </p>
                                          <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                          >
                                            <DateTimePicker
                                              label="Add dates & times"
                                              slotProps={{
                                                actionBar: {
                                                  actions: [
                                                    "clear",
                                                    "today",
                                                    "accept",
                                                  ],
                                                },
                                              }}
                                              value={GatingDateAccessStartDate}
                                              onChange={handleStartDateChange}
                                              slots={{
                                                openPickerIcon:
                                                  DatetimePickerIcon,
                                              }}
                                              sx={{
                                                // "& .MuiPopper-root.MuiPickersPopper-root.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root": {
                                                //   height:"300px",
                                                //   border: "2px solid red",
                                                // },
                                                "& .MuiOutlinedInput-root": {
                                                  padding: "0px 20px 0px 20px",
                                                },
                                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    borderRadius: "30px",
                                                  },
                                                "& .MuiButtonBase-root": {
                                                  marginRight: "10px",
                                                },
                                                "& .MuiInputBase-input": {
                                                  height: "auto",
                                                },
                                                "& .MuiInputLabel-formControl":
                                                  {
                                                    marginTop: "5px",
                                                  },
                                                "& .MuiFormLabel-root": {
                                                  marginTop: "0px",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </div>

                                        <div className="w-[50%] pl-2">
                                          <p className="text-lg mb-[2.5%]">
                                            End Date (Optional)
                                          </p>
                                          <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                          >
                                            <DateTimePicker
                                              label="Add dates & times"
                                              slotProps={{
                                                actionBar: {
                                                  actions: [
                                                    "clear",
                                                    "today",
                                                    "accept",
                                                  ],
                                                },
                                              }}
                                              value={GatingDateAccessEndDate}
                                              onChange={handleEndDateChange}
                                              slots={{
                                                openPickerIcon:
                                                  DatetimePickerIcon,
                                              }}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                  padding: "0px 20px 0px 20px",
                                                },
                                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    borderRadius: "30px",
                                                    border: "1px solid #CBD5E1",
                                                  },
                                                "& .MuiButtonBase-root": {
                                                  marginRight: "10px",
                                                },
                                                "& .MuiInputLabel-formControl":
                                                  {
                                                    marginTop: "5px",
                                                  },
                                                "& .MuiFormLabel-root": {
                                                  marginTop: "0px",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </div>
                                      </div>
                                      <div className="flex flex-row text-md pb-[3%]">
                                        <p>
                                          For more guidance, understand&nbsp;
                                        </p>
                                        <Link
                                          to="https://t4.ftcdn.net/jpg/00/97/58/97/360_F_97589769_t45CqXyzjz0KXwoBZT9PRaWGHRk5hQqQ.jpg"
                                          target="_blank"
                                          className="text-[#155A9F]"
                                          title="Gating Document"
                                        >
                                          how gating works.
                                        </Link>
                                      </div>
                                      <div className="flex justify-end pb-[3%]">
                                        <button
                                          className="bg-[#155A9F] rounded-full px-[6%] py-[2.5%] text-white text-sm"
                                          disabled={isDisabledDateAccess}
                                          onClick={
                                            isDateAccessActive
                                              ? updateDateAccess
                                              : activateDateAccess
                                          }
                                        >
                                          {isDateAccessActive
                                            ? "Update Gating"
                                            : "Set Gating"}
                                        </button>
                                      </div>
                                      {!enableDateAccess ? (
                                        <div className="absolute rounded-bl-[24px] rounded-br-[24px] top-0 left-0 w-full h-full bg-white opacity-50 z-2"></div>
                                      ) : null}
                                    </div>

                                    <div className="h-10 flex flex-wrap">
                                      {isSnackbarOpen ? (
                                        <>
                                          <div className="flex bg-[#0F172A] rounded-[8px] hover:cursor-default text-white p-2">
                                            <SuccessIcon />
                                            <p className="ml-2">
                                              {snackbarMessage}
                                            </p>
                                            <div
                                              className="hover:cursor-pointer ml-2"
                                              onClick={closeSnackbar}
                                            >
                                              <CloseAlertIcon />
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </>
                                ) : // Passcode or NFT Token
                                isPasscodeOpen ? (
                                  <>
                                    <div className="bg-white px-[10%] rounded-bl-[24px] rounded-br-[24px] relative z-1 max-h-[72vh] overflow-y-auto">
                                      <p className="pt-[4%] pb-[3%] text-lg">
                                        Choose Your Gating Type
                                      </p>
                                      <div className="flex flex-row justify-between pb-[4%]">
                                        <button
                                          className={`w-[50%] border border-2 rounded-2xl py-[3%] px-[5%] bg-[#F8FAFC] bg-opacity-50 w-[100%] mr-[3%] text-left ${
                                            isClickPasscode
                                              ? "border-blue-500"
                                              : ""
                                          }`}
                                          onClick={handleClickPasscode}
                                        >
                                          <p className="font-semibold text-lg">
                                            Passcode
                                          </p>
                                          <p className="text-sm">
                                            Lorem ipsum dolor sit amet
                                            consectetur.
                                          </p>
                                        </button>
                                        <button
                                          className={`w-[50%] border border-2 rounded-2xl py-[3%] px-[5%] bg-[#F8FAFC] bg-opacity-50 w-[100%] mr-[3%] text-left ${
                                            isClickNFT ? "border-blue-500" : ""
                                          }`}
                                          onClick={handleClickNFT}
                                        >
                                          <p className="font-semibold">
                                            NFT Token
                                          </p>
                                          <p className="text-sm">
                                            Lorem ipsum dolor sit amet
                                            consectetur.
                                          </p>
                                        </button>
                                      </div>

                                      {/* User selects 'Passcode or NFT Token' --> 'Passcode' */}
                                      {isClickPasscode ? (
                                        <>
                                          <div className="w-full">
                                            <p className="text-lg mb-2">
                                              Passcode
                                            </p>
                                            <div className="w-[50%] mb-2">
                                              <TextField
                                                type="password"
                                                onChange={handlePasscodeChange}
                                                value={GatingPasscode}
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                  "& .MuiInputBase-root": {
                                                    borderRadius: "43px",
                                                  },
                                                  "& .MuiInputBase-input": {
                                                    "padding-left": "30px",
                                                  },
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {!isValidPasscode ? (
                                            <p className="text-sm text-[#B91C1C]">
                                              The input field is invalid. Try
                                              again with 6-9 alphanumeric
                                              characters only.
                                            </p>
                                          ) : (
                                            <p className="text-sm">
                                              Enter 6-9 alphanumeric characters
                                              only.
                                            </p>
                                          )}
                                          <div className="flex flex-row text-md py-[3%] ">
                                            <p>
                                              For more guidance,
                                              understand&nbsp;
                                            </p>
                                            <Link
                                              to="https://cdn.pixabay.com/photo/2023/08/18/15/02/dog-8198719_640.jpg"
                                              target="_blank"
                                              className="text-[#155A9F]"
                                              title="Gating Document"
                                            >
                                              how gating works.
                                            </Link>
                                          </div>
                                          <div className="flex justify-end pb-[3%]">
                                            <button
                                              className="bg-[#3462AF] rounded-full px-[6%] py-[2.5%] text-white text-sm"
                                              disabled={isDisabledPasscode}
                                              onClick={
                                                isPasscodeActive
                                                  ? updatePasscode
                                                  : activatePasscode
                                              }
                                            >
                                              {isPasscodeActive
                                                ? "Update Gating"
                                                : "Set Gating"}
                                            </button>
                                          </div>
                                        </>
                                      ) : // User selects 'Passcode or NFT Token' --> 'NFT Token'
                                      isClickNFT ? (
                                        <>
                                          <div className="flex flex-row w-[100%] h-auto text-lg pb-[4%] ">
                                            <div className="w-[100%] mr-[3%] h-auto">
                                              <p className="mb-2">Blockchain</p>
                                              <FormControl fullWidth>
                                                <Select
                                                  defaultValue={10}
                                                  onChange={
                                                    handleBlockchainChange
                                                  }
                                                  input={<OutlinedInput />}
                                                  IconComponent={
                                                    ArrowDropdownIcon
                                                  }
                                                  sx={{
                                                    borderRadius: "43px",
                                                    height: "56px",
                                                    "& .MuiSelect-icon.MuiSelect-iconOutlined.css-3qbkez-MuiSelect-icon":
                                                      {
                                                        top: "16px",
                                                        right: "16px",
                                                      },
                                                    "& .MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen":
                                                      {
                                                        top: "16px",
                                                        right: "16px",
                                                      },
                                                    "& .MuiSelect-select": {
                                                      "font-weight": "bold",
                                                      "padding-left": "30px",
                                                    },
                                                    "& .MuiInputBase-root": {
                                                      "border-radius": "30px",
                                                    },
                                                  }}
                                                >
                                                  <MenuItem value={10}>
                                                    ETHEREUM
                                                  </MenuItem>
                                                  <MenuItem value={20}>
                                                    TRON
                                                  </MenuItem>
                                                  <MenuItem value={30}>
                                                    RIPPLE
                                                  </MenuItem>
                                                  <MenuItem value={40}>
                                                    EOS
                                                  </MenuItem>
                                                  <MenuItem value={50}>
                                                    STELLAR
                                                  </MenuItem>
                                                  <MenuItem value={60}>
                                                    SOLANA
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </div>
                                            <div className="w-[100%] h-auto">
                                              <div className="flex flex-row mb-2">
                                                <p className="">
                                                  Contract Type
                                                </p>
                                                <div
                                                  data-tooltip-place="right"
                                                  data-tooltip-id="contract_type"
                                                  data-tooltip-html="<p style='margin-bottom: 10px'>Contract Type</p><p>Lorem ipsum dolor sit</p><p>amet consectetur.</p>"
                                                >
                                                  <QuestionMarkIcon />
                                                </div>
                                              </div>
                                              <Tooltip
                                                id="contract_type"
                                                className="z-[999]"
                                                style={{
                                                  backgroundColor: "#0F172A",
                                                  paddingBlock: 20,
                                                  paddingInline: 30,
                                                  borderRadius: 15,
                                                }}
                                              />
                                              <FormControl fullWidth>
                                                <Select
                                                  defaultValue={10}
                                                  onChange={
                                                    handleContractChange
                                                  }
                                                  input={<OutlinedInput />}
                                                  IconComponent={
                                                    ArrowDropdownIcon
                                                  }
                                                  sx={{
                                                    borderRadius: "43px",
                                                    height: "56px",
                                                    "& .MuiSelect-icon.MuiSelect-iconOutlined.css-3qbkez-MuiSelect-icon":
                                                      {
                                                        top: "16px",
                                                        right: "16px",
                                                      },
                                                    "& .MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen":
                                                      {
                                                        top: "16px",
                                                        right: "16px",
                                                      },
                                                    "& .MuiSelect-select": {
                                                      "font-weight": "bold",
                                                      "padding-left": "30px",
                                                    },
                                                    "& .MuiInputBase-root": {
                                                      "border-radius": "30px",
                                                    },
                                                  }}
                                                >
                                                  <MenuItem value={10}>
                                                    ERC-721
                                                  </MenuItem>
                                                  <MenuItem value={20}>
                                                    ERC-721A
                                                  </MenuItem>
                                                  <MenuItem value={30}>
                                                    ERC-1155
                                                  </MenuItem>
                                                  <MenuItem value={40}>
                                                    ERC-20
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </div>
                                          </div>
                                          <div className="pb-[4%]">
                                            <div className="flex flex-row mb-2">
                                              <p className="text-lg">
                                                Contract Address
                                              </p>
                                              <div
                                                data-tooltip-place="right"
                                                data-tooltip-id="contract_address"
                                                data-tooltip-html="<p style='margin-bottom: 10px'>Contract Address</p><p>Lorem ipsum dolor sit</p><p>amet consectetur.</p>"
                                              >
                                                <QuestionMarkIcon />
                                              </div>
                                              <Tooltip
                                                id="contract_address"
                                                className="z-[999]"
                                                style={{
                                                  backgroundColor: "#0F172A",
                                                  paddingBlock: 20,
                                                  paddingInline: 30,
                                                  borderRadius: 15,
                                                }}
                                              />
                                            </div>
                                            <TextField
                                              onChange={handleContractAddress}
                                              value={GatingContractAddress}
                                              variant="outlined"
                                              fullWidth
                                              sx={{
                                                "& .MuiInputBase-root": {
                                                  borderRadius: "43px",
                                                },
                                                "& .MuiInputBase-input": {
                                                  "padding-left": "30px",
                                                },
                                              }}
                                            />
                                          </div>
                                          <div className="w-full pb-[4%]">
                                            <div className="w-[50%]">
                                              <div className="mb-2 flex flex-row">
                                                <p className="text-lg">
                                                  Minimum Quantity
                                                </p>
                                                <div
                                                  data-tooltip-place="right"
                                                  data-tooltip-id="contract_quantity"
                                                  data-tooltip-html="<p style='margin-bottom: 10px'>Contract Quantity</p><p>Lorem ipsum dolor sit</p><p>amet consectetur.</p>"
                                                >
                                                  <QuestionMarkIcon />
                                                </div>
                                                <Tooltip
                                                  id="contract_quantity"
                                                  className="z-[999]"
                                                  style={{
                                                    backgroundColor: "#0F172A",
                                                    paddingBlock: 20,
                                                    paddingInline: 30,
                                                    borderRadius: 15,
                                                  }}
                                                />
                                              </div>
                                              <TextField
                                                onChange={handleMinimumQuantity}
                                                value={GatingMinimumQuantity}
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                  "& .MuiInputBase-root": {
                                                    borderRadius: "43px",
                                                  },
                                                  "& .MuiInputBase-input": {
                                                    "padding-left": "30px",
                                                  },
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="flex flex-row text-md pb-[4%]">
                                            <p>
                                              For more guidance,
                                              understand&nbsp;
                                            </p>
                                            <Link
                                              to="https://miro.medium.com/v2/resize:fit:1200/1*buIz-XdCPbJk9pnY03xVew.jpeg"
                                              target="_blank"
                                              className="text-[#155A9F]"
                                              title="Gating Document"
                                            >
                                              how gating works.
                                            </Link>
                                          </div>
                                          <div className="flex justify-end pb-[3%]">
                                            <button
                                              className="bg-[#155A9F] rounded-full px-[6%] py-[2.5%] text-white text-sm"
                                              disabled={isDisabledPasscode}
                                              onClick={
                                                isPasscodeActive
                                                  ? updatePasscode
                                                  : activatePasscode
                                              }
                                            >
                                              {isPasscodeActive
                                                ? "Update Gating"
                                                : "Set Gating"}
                                            </button>
                                          </div>
                                        </>
                                      ) : null}

                                      {!enablePasscodeAccess ? (
                                        <div className="absolute rounded-bl-2xl rounded-br-2xl top-0 left-0 w-full h-full bg-white opacity-50 z-2"></div>
                                      ) : null}
                                    </div>

                                    <div className="h-10 flex flex-wrap">
                                      {isSnackbarOpen ? (
                                        <>
                                          <div className="flex bg-[#0F172A] rounded-[8px] hover:cursor-default text-white p-2">
                                            <SuccessIcon />
                                            <p className="ml-2">
                                              {snackbarMessage}
                                            </p>
                                            <div
                                              className="hover:cursor-pointer ml-2"
                                              onClick={closeSnackbar}
                                            >
                                              <CloseAlertIcon />
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </>
                                ) : null
                              }
                            </div>
                            <div
                              className="h-[45px] w-[45px] hover:cursor-pointer bg-black bg-opacity-10 rounded-full p-3 flex items-center justify-center"
                              onClick={handleGatingPopupClose}
                            >
                              <CloseModalIcon className="" />
                            </div>
                            {attemptClose && isProgress ? (
                              <>
                                <div className="fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 flex justify-center items-center">
                                  <div className="bg-white z-1 w-[437px] h-[203px] rounded-[24px] pl-[2%]">
                                    <div className="h-[100%] flex flex-col items-start justify-center">
                                      <p className="text-lg font-semibold">
                                        Confirm leaving?
                                      </p>
                                      <p className="mt-[1%] mb-[4%]">
                                        Leaving will not save the current
                                        progress
                                      </p>
                                      <div className="flex flex-row">
                                        <button
                                          className="bg-[#155A9F] text-lg rounded-[69px] w-[110px] h-[54px] text-white"
                                          onClick={handleLeave}
                                        >
                                          Leave
                                        </button>
                                        <button
                                          className="bg-white text-lg rounded-[69px] w-[110px] h-[54px] text-[#155A9F] border-[2px] border-[#E2E8F0] ml-[3%]"
                                          onClick={handleStay}
                                        >
                                          Stay
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {toggleGatingAlert ? (
                              <>
                                <div className="fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 flex justify-center items-center">
                                  <div className="bg-white z-1 w-[437px] h-[203px] rounded-[24px] pl-[2%]">
                                    <div className="h-[100%] flex flex-col items-start justify-center">
                                      <p className="text-lg font-semibold">
                                        Confirm disable gating access?
                                      </p>
                                      <p className="mt-[1%] mb-[4%]">
                                        Turning off the toggle will disable the
                                        gating access.
                                      </p>
                                      <div className="flex flex-row">
                                        <button
                                          className="bg-[#155A9F] text-lg rounded-[69px] w-[110px] h-[54px] text-white"
                                          onClick={handleConfirmDisable}
                                        >
                                          Confirm
                                        </button>
                                        <button
                                          className="bg-white text-lg rounded-[69px] w-[110px] h-[54px] text-[#155A9F] border-[2px] border-[#E2E8F0] ml-[3%]"
                                          onClick={handleCancelDisable}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {/* --------------------------------------------------------------------------- */}
                  </div>
                  <div className="flex flex-row items-center justify-between px-3.5 relative">
                    <div
                      className="flex flex-row items-center gap-1.5"
                      data-tooltip-place="right"
                      data-tooltip-id="multiplayer"
                      data-tooltip-html="<p style='margin-bottom: 10px'>Multiplayer</p> <p>Number of visitors in your World <br />at one time.</p>"
                    >
                      <p>Multiplayer</p>

                      <div className="bg-[#F1F5F9] rounded-full h-[18px] w-[18px] flex items-center justify-center hover:cursor-pointer">
                        <p className="text-[#94A3B8] font-medium text-sm">?</p>
                      </div>
                    </div>
                    <Tooltip
                      id="multiplayer"
                      className="z-[999]"
                      style={{
                        backgroundColor: "#0F172A",
                        paddingBlock: 20,
                        paddingInline: 30,
                        borderRadius: 15,
                      }}
                    />
                    <p>30</p>
                  </div>
                  <div className="flex flex-row items-center justify-between px-3.5 relative">
                    <div
                      className="flex flex-row items-center gap-1.5"
                      data-tooltip-place="right"
                      data-tooltip-id="storage"
                      data-tooltip-html="<p style='margin-bottom: 10px'>Storage</p> <p>Total storage capacity of <br />your World.</p>"
                    >
                      <p>Storage</p>

                      <div className="bg-[#F1F5F9] rounded-full h-[18px] w-[18px] flex items-center justify-center hover:cursor-pointer">
                        <p className="text-[#94A3B8] font-medium text-sm">?</p>
                      </div>
                    </div>
                    <Tooltip
                      id="storage"
                      className="z-[999]"
                      style={{
                        backgroundColor: "#0F172A",
                        paddingBlock: 20,
                        paddingInline: 30,
                        borderRadius: 15,
                      }}
                    />
                    <p>238 MB of 500 MB used</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-6">
                <div className="flex gap-4 items-center">
                  <p>Share to</p>
                  <SocialLinks worldStatus={worldStatus} />
                </div>
                <div className="flex gap-2 items-center">
                  {worldStatus == 2 ? (
                    <p
                      className="text-[#EF4444] font-medium text-sm mx-4 hover:cursor-pointer"
                      onClick={() => changeConfirmationModal("delete")}
                    >
                      Delete World
                    </p>
                  ) : (
                    <Button
                      buttonTitle="Save"
                      buttonColor="[#155A9F]"
                      buttonDisabled={!canUpdate}
                      hoverColor="hover-primary-btn"
                      handleClick={() => saveWorld(worldDetails.world_id)}
                    />
                  )}

                  {worldStatus == 0 ? (
                    <Button
                      buttonTitle="Publish"
                      buttonColor={
                        blueprint || templateId !== "0"
                          ? "[#10B981]"
                          : "unClick-btn"
                      }
                      hoverColor="[#0c9c6c]"
                      handleClick={() =>
                        blueprint || templateId !== "0"
                          ? changeConfirmationModal("publish")
                          : ""
                      }
                    />
                  ) : worldStatus == 1 ? (
                    <Button
                      buttonTitle="Unpublish"
                      specialColor="unpublish"
                      handleClick={() => changeConfirmationModal("unpublish")}
                    />
                  ) : (
                    worldStatus == 2 && (
                      <Button
                        buttonTitle="Renew Plan"
                        buttonColor="[#10B981]"
                        hoverColor="[#0c9c6c]"
                        handleClick={() =>
                          navigate("/worlds/upgrade", {
                            state: {
                              worldId: worldDetails.world_id,
                            },
                          })
                        }
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal ? (
        <BluePrintModal
          worldId={worldDetails.world_id}
          worldType={worldDetails.mitopro_feature}
          closeModal={() => changeBlueprintModal()}
          selectBlueprint={(blueprint, id, requirespro) => {
            selectBlueprint(blueprint, id);
            requirespro ? setRequireSub(true) : setRequireSub(false);
          }}
          jwt={jwt}
        />
      ) : null}

      {showDetailsModal ? (
        <BluePrintDetailModal
          blueprint={blueprint}
          blueprintTempImg={tempBlueprint}
          blueprintId={blueprintId}
          requireSub={requireSub}
          worldDetails={worldDetails}
          worldId={worldDetails.world_id}
          worldType={worldDetails.mitopro_feature}
          closeModal={() => changeBlueprintDetailModal()}
          applyBlueprint={(blueprintImg, worldId, templateId) =>
            applyBlueprint(blueprintImg, worldId, templateId)
          }
          experienceWorld={() => console.log("Experience world")}
          removeBlueprint={() => removeBlueprint()}
          jwt={jwt}
          templateId={templateId}
        />
      ) : null}

      {showConfirmationModal ? (
        <ConfirmationModal
          action={action}
          worldId={worldDetails.world_id}
          closeModal={() => changeConfirmationModal()}
          publishWorld={() => publishWorld(worldDetails.world_id)}
          unpublishWorld={() => unpublishWorld(worldDetails.world_id)}
          unlinkBlueprint={() => unlinkBlueprint(worldDetails.world_id)}
          deleteWorld={() => deleteWorld(worldDetails.world_id)}
          handleResetCropImage={() => resetCropImage(worldDetails.world_id)}
        />
      ) : null}
      {showCropModal ? (
        <ChangeCropModal
          closeModal={() => changeCropModal()}
          image={image}
          handleCropImage={(img) => {
            setCroppedImage(img);
            setWorldThumbnail(img);
            setCanUpdate(true);
          }}
        />
      ) : null}
    </Layout>
  );
};

const BluePrintModal = ({
  closeModal,
  worldId,
  worldType,
  selectBlueprint,
  jwt,
}) => {
  const [selected, setSelected] = useState(1);
  const handleClick = (divNum) => () => {
    setSelected(divNum);
  };

  const [liteTemplates, setLiteTemplates] = useState([]);
  const [proTemplates, setProTemplates] = useState([]);

  useEffect(() => {
    getLiteTemplates();
    getProTemplates();
  }, []);

  async function getProTemplates() {
    let body = new FormData();
    body.append("tier", 1);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/template-data/search`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        setLiteTemplates(response.data.templateDetails);
      })
      .catch((err) => {
        console.error("err", err);
        setLiteTemplates([]);
      });
  }

  async function getLiteTemplates() {
    let body = new FormData();
    body.append("tier", 2);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/template-data/search`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        setProTemplates(response.data.templateDetails);
      })
      .catch((err) => {
        console.error("err", err);
        setProTemplates([]);
      });
  }

  return (
    <>
      {/* <div style={selected == 1 ? active : inactive} onClick={handleClick(1)}>
        click here 1
      </div> */}
      <div className="overflow-y-scroll fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-7xl flex flex-row justify-center gap-2 blueprint-div">
          {/*content*/}
          <div className="ml-10 border-0 rounded-[24px] shadow-lg relative flex flex-col w-[1098px] h-fit bg-white outline-none focus:outline-none px-10 pt-10 pb-8">
            {/*header*/}
            <div className="flex items-start justify-between rounded-t mb-8">
              <h3 className="text-lg font-semibold">Choose your blueprint</h3>
              <div className="flex gap-3 ">
                <p className="text-[#64748B] text-base font-medium">
                  <a
                    onClick={handleClick(1)}
                    className={`hover:cursor-pointer ${
                      selected == "1" && "text-[#0F172A]"
                    }`}
                  >
                    All
                  </a>
                </p>
                <p className="text-[#64748B] text-base">/</p>
                <p className="text-[#64748B] text-base font-medium">
                  <a
                    onClick={handleClick(2)}
                    className={`hover:cursor-pointer ${
                      selected == "2" && "text-[#0F172A]"
                    }`}
                  >
                    Free
                  </a>
                </p>
                <p className="text-[#64748B] text-base">/</p>
                <p className="text-[#64748B] text-base font-medium">
                  <a
                    onClick={handleClick(3)}
                    className={`hover:cursor-pointer ${
                      selected == "3" && "text-[#0F172A]"
                    }`}
                  >
                    MitoPro
                  </a>
                </p>
                {/* <p className="text-[#64748B] text-base">/</p>
                <p className="text-[#64748B] text-base font-medium">
                  <a onClick={handleClick(4)} className={`hover:cursor-pointer ${ selected == "4" && "text-[#0F172A]"}`}>
                    Collabs-only
                  </a>
                </p>
                <p className="text-[#64748B] text-base">/</p>
                <p className="text-[#64748B] text-base font-medium">
                  <a onClick={handleClick(5)} className={`hover:cursor-pointer ${ selected == "5" && "text-[#0F172A]"}`}>
                    Collected
                  </a>
                  
                </p> */}
              </div>
            </div>
            {/*body*/}
            <div>
              {selected == 1 || selected == 2 ? (
                <>
                  {/* mitolite */}
                  <div className="relative flex-auto border-b border-[#CBD5E1] pb-10">
                    <p className="mb-4 text-lg font-medium">Free</p>
                    <div className="flex flex-row gap-8 overflow-auto scrollbar scrollbar-thumb-white scrollbar-track-white">
                      {liteTemplates.map((template) => (
                        <button
                          onClick={() =>
                            selectBlueprint(
                              template.template_pic
                                ? template.template_pic
                                : template.template_thumbnail
                                ? template.template_thumbnail
                                : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png",
                              template.template_id
                            )
                          }
                          key={template.template_id}
                          to="world-details"
                          className="flex flex-col basis-1/4 items-center"
                        >
                          <div className="flex items-center min-w-[190px] max-w-[242px] p-[16px] rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 mb-4 overflow-hidden">
                            {/* <div className="flex items-center p-[16px] rounded-full bg-[#F1F5F9] mb-4 overflow-hidden"> */}
                            <img
                              src={
                                template.template_pic
                                  ? template.template_pic
                                  : template.template_thumbnail
                                  ? template.template_thumbnail
                                  : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                              }
                              className="w-[200px] object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                              alt=""
                            />
                          </div>
                          <p className="text-center font-medium text-lg mb-2">
                            {template.template_name}
                          </p>
                          <p className="text-center font-medium text-base mb-5">
                            by {template.creator_name}
                          </p>
                        </button>
                      ))}

                      {liteTemplates.length < 4 ? (
                        <>
                          <div className="flex flex-col basis-1/4 items-center"></div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {selected == 1 || selected == 3 ? (
                <>
                  {/* mitopro */}
                  <div
                    className={`relative flex-auto  ${
                      selected == "1" && "pt-5"
                    }`}
                  >
                    <div className="flex flex-row justify-between">
                      <p className="mb-4 text-lg font-medium">MitoPro</p>
                      {worldType == 0 && (
                        <Link to="/worlds/upgrade" state={{ worldId }}>
                          <p className="mb-4 text-lg font-medium text-[#155A9F] hover:text-[#10B981]">
                            Upgrade MitoPro
                          </p>
                        </Link>
                      )}
                    </div>
                    <div className="flex flex-row gap-8 overflow-auto scrollbar scrollbar-thumb-white scrollbar-track-white">
                      {proTemplates.map((template) => (
                        <button
                          onClick={() =>
                            selectBlueprint(
                              template.template_pic
                                ? template.template_pic
                                : template.template_thumbnail
                                ? template.template_thumbnail
                                : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png",
                              template.template_id,
                              "mitopro"
                            )
                          }
                          key={template.template_id}
                          to="world-details"
                          className="flex flex-col basis-1/4 items-center"
                          handleTemplateId={template.template_id}
                        >
                          <div className="flex min-w-[190px] max-w-[242px] items-center p-[16px] rounded-full transition ease-in-out duration-300 bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 mb-4 overflow-hidden">
                            <img
                              src={
                                template.template_pic
                                  ? template.template_pic
                                  : template.template_thumbnail
                                  ? template.template_thumbnail
                                  : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                              }
                              className="w-[200px] object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                              alt=""
                            />
                          </div>
                          <p className="text-center font-medium text-lg mb-2">
                            {template.template_name}
                          </p>
                          <p className="text-center font-medium text-base mb-4">
                            by {template.creator_name}
                          </p>
                        </button>
                      ))}
                      {proTemplates.length < 4 ? (
                        <>
                          <div className="flex flex-col basis-1/4 items-center"></div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* close button */}
          <div
            className="bg-[#000]/[0.3] mt-[-50px] ml-[-10px] w-[56px] flex h-[56px] justify-center items-center rounded-full hover:cursor-pointer"
            onClick={() => closeModal()}
          >
            <span className="text-white">
              <Unicons.UilTimes size={50} />
            </span>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

const BluePrintDetailModal = ({
  worldDetails,
  blueprint,
  blueprintTempImg,
  requireSub,
  worldId,
  worldType,
  closeModal,
  applyBlueprint,
  experienceWorld,
  removeBlueprint,
  blueprintId,
  jwt,
  templateId,
}) => {
  const navigate = useNavigate();

  const [templateDetail, setTemplateDetail] = useState([]);
  const [templateImages, setTemplateImages] = useState([]);

  useEffect(() => {
    getTemplateDetail();
  }, []);

  async function getTemplateDetail() {
    let body = new FormData();
    body.append("template_id", blueprintId);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/template-data/search`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        setTemplateDetail(response.data.templateDetails[0]);

        if (response.data.templateDetails[0].template_gallery !== 0) {
          setTemplateImages(
            JSON.parse(response.data.templateDetails[0].template_gallery)
              .template_images
          );
        }
      })
      .catch((err) => {
        console.error("err", err);
        setTemplateDetail([]);
      });
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-3xl flex flex-row gap-2">
          {/*content*/}
          <div className="ml-10 border-0 rounded-3xl shadow-lg relative flex flex-row w-full max-w-[800px] bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="world-detail basis-4/12 flex items-start justify-between rounded-l-3xl w-[350px] h-auto overflow-hidden">
              <Carousel
                showArrows={false}
                onSwipeStart
                swipeable={true}
                showThumbs={false}
                showStatus={false}
                className="h-full"
              >
                {templateImages.map((templateImage, index) => (
                  <img
                    src={templateImage}
                    className="object-cover h-full"
                    alt=""
                  />
                ))}
              </Carousel>

              {templateImages.length == 0 && (
                <Carousel
                  showArrows={false}
                  onSwipeStart
                  swipeable={true}
                  showThumbs={false}
                  showStatus={false}
                  className="h-full"
                >
                  <img
                    src={
                      templateDetail.template_picture
                        ? templateDetail.template_picture
                        : templateDetail.thumbnail
                        ? templateDetail.thumbnail
                        : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                    }
                    className="object-cover h-full"
                    alt=""
                  />
                </Carousel>
              )}
            </div>
            {/*body*/}
            <div className="relative basis-8/12 flex-auto border-[#CBD5E1] pb-10 px-7 py-8 w-full">
              <p className="mb-2 text-2xl font-medium text-[#0F172A]">
                {templateDetail.template_name}
              </p>
              <p className="mb-6 font-medium text-[#334155]">
                by {templateDetail.creator_name}
              </p>
              <p className="mb-4 text-[#334155]">
                {templateDetail.template_description}
              </p>

              <div className="flex flex-col my-8">
                <div className="flex flex-row">
                  <p className="basis-1/4 text-[#334155]">Pax</p>
                  <p className="text-[#334155]">
                    {templateDetail.pax_description}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="basis-1/4 text-[#334155]">Size</p>
                  <p className="text-[#334155]">
                    {templateDetail.size_description}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="basis-1/4 text-[#334155]">Suitable for</p>
                  <p className="text-[#334155]">
                    {templateDetail.suitable_description}
                  </p>
                </div>
              </div>

              <p className="mb-6 text-[#334155]">
                Colours variations and customisation are available inside
                Mitoworld
              </p>

              {blueprint || templateId !== "0" ? (
                <Button
                  buttonTitle="Unlink Blueprint"
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => removeBlueprint()}
                />
              ) : (
                <div className="flex gap-2">
                  {requireSub == true && worldType == 0 ? (
                    <Button
                      buttonTitle="Upgrade to Use"
                      buttonColor="[#155A9F]"
                      hoverColor="hover-primary-btn"
                      handleClick={() =>
                        navigate("/worlds/upgrade", {
                          state: {
                            worldId,
                          },
                        })
                      }
                    />
                  ) : (
                    <Button
                      buttonTitle="Apply Blueprint"
                      buttonColor="[#155A9F]"
                      hoverColor="hover-primary-btn"
                      titleSize="font-medium"
                      handleClick={() =>
                        applyBlueprint(
                          blueprintTempImg,
                          worldId,
                          templateDetail.template_id
                        )
                      }
                    />
                  )}
                  <Button
                    buttonTitle="Experience"
                    buttonColor="[#F8FAFC]"
                    hoverColor="[#E2E8F0]"
                    titleColor="#155A9F"
                    borderColor="#E2E8F0"
                    titleSize="font-medium"
                    handleClick={() => {
                      window.location = templateDetail.link_demo;
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* close button */}
          <div
            className="bg-[#000]/[0.3] mt-[-50px] ml-[-10px] w-[56px] flex h-[56px] justify-center items-center rounded-full hover:cursor-pointer"
            onClick={() => closeModal()}
          >
            <span className="text-white">
              <Unicons.UilTimes size={50} />
            </span>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ConfirmationModal = ({
  closeModal,
  action,
  publishWorld,
  unpublishWorld,
  unlinkBlueprint,
  deleteWorld,
  handleResetCropImage,
  worldId,
}) => {
  let copyMessage = [
    {
      action: "publish",
      title: "Confirm publish World?",
      description: "By publishing the World set to public for people to visit.",
      helperText: "You may unpublish it anytime.",
      confirmText: "Publish",
    },
    {
      action: "unpublish",
      title: "Confirm unpublish World?",
      description:
        "Unpublishing the World will remove it from all search functions and no longer be accessible by visitors. You may choose to publish your site again anytime.",
      confirmText: "Unpublish",
    },
    {
      action: "unlink",
      title: "Confirm unlink blueprint?",
      description:
        "Unlinking blueprint will detach all assets, and unpublish World.",
      helperText:
        "Uploaded assets will remain in database, reapply blueprint to access those assets.",
      confirmText: "Yes, unlink blueprint",
    },
    {
      action: "delete",
      title: "Confirm delete World?",
      description:
        "Deleting your World will erase all details, unlink blueprint and remove all assets in the World.",
      helperText:
        "For MitoPro World, the subscription details will be removed.",
      confirmText: "Yes, delete World",
    },
    {
      action: "reset_thumbnail",
      title: "Confirm reset thumbnail?",
      description:
        " Resetting thumbnail will revert back to the selected blueprint thumbnail.",
      confirmText: "Reset",
    },
  ];
  let copy = copyMessage.find((item) => item.action === action);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl flex flex-row gap-2">
          {/*content*/}
          <div className="ml-10 border-0 rounded-[24px] shadow-lg relative flex flex-col w-[437px] bg-white outline-none focus:outline-none px-6 pt-10 pb-8">
            {/*header*/}
            <div className="flex items-start justify-between rounded-t mb-2">
              <h3 className="text-lg font-medium">{copy.title}</h3>
            </div>
            {/*body*/}
            <div className="relative flex-auto">
              <p className="mb-10 text-[#334155]">{copy.description}</p>
              {copy.helperText && (
                <p className="mb-6 text-[14px] font-medium text-[#F59E0B]">
                  {copy.helperText}
                </p>
              )}
            </div>
            {/*buttons*/}
            <div className="flex gap-2">
              {action == "publish" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => publishWorld(worldId)}
                />
              )}
              {action == "unpublish" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => unpublishWorld(worldId)}
                />
              )}
              {action == "reset_thumbnail" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => handleResetCropImage()}
                />
              )}
              {action == "delete" || action == "unlink" ? (
                <Button
                  buttonTitle="Cancel"
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => closeModal()}
                />
              ) : (
                <Button
                  buttonTitle="Cancel"
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  hoverColor="[#F1F5F9]"
                  titleColor="#155A9F"
                  handleClick={() => closeModal()}
                />
              )}
              {action == "unlink" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  titleColor="#B91C1C"
                  handleClick={() => unlinkBlueprint(worldId)}
                />
              )}
              {action == "delete" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  titleColor="#B91C1C"
                  handleClick={() => deleteWorld(worldId)}
                />
              )}
            </div>
          </div>
          {/* close button */}
          <div
            className="bg-[#000]/[0.3] mt-[-50px] ml-[-10px] w-[56px] flex h-[56px] justify-center items-center rounded-full hover:cursor-pointer"
            onClick={() => closeModal()}
          >
            <span className="text-white">
              <Unicons.UilTimes size={50} />
            </span>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ChangeCropModal = ({ closeModal, image, handleCropImage }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-5xl flex flex-row gap-2">
          {/*content*/}
          <div className="ml-10 border-0 rounded-lg shadow-lg relative flex flex-col w-[599px] bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              {/* close button */}
              <div
                className="mr-2 justify-center items-center rounded-full hover:cursor-pointer"
                onClick={() => closeModal()}
              >
                <span className="text-black">
                  <Unicons.UilArrowLeft size={30} />
                  {/* <Unicons.UilTimes size={30} /> */}
                </span>
              </div>
              <h3 className="text-xl font-semibold">Edit Thumbnail</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeModal()}
              >
                <span className="bg-transparent text-black opacity-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <EasyCrop
                className="cropDiv"
                image={image}
                cropImage={(img) => handleCropImage(img)}
                closeTheModal={() => closeModal()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default WorldDetail;
