import React, { useEffect, useState, useRef } from 'react';
import { ResponsiveContainer,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Layout from "../../components/Layout";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const WorldAnalytics = ({ userData, jwt }) => {
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const sortData = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
      };

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const options = ['Option 1', 'Option 2', 'Option 3'];

    const toggleDropdown = () => setIsOpen(!isOpen);

    const toggleOption = (option) => {
        setSelectedOptions((prevSelectedOptions) => {
        if (prevSelectedOptions.includes(option)) {
            return prevSelectedOptions.filter((prevOption) => prevOption !== option);
        } else {
            return [...prevSelectedOptions, option];
        }
        });
    };

    const CustomLegend = ({ payload }) => {
        return (
        <ul style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {payload.map((entry, index) => (
                <li key={`item-${index}`} style={{ listStyleType: 'none', display: 'flex', alignItems: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
                    <svg width="20" height="10">
                        <line 
                            x1="0" 
                            y1="5" 
                            x2="20" 
                            y2="5" 
                            stroke={entry.color} 
                            strokeWidth="2" 
                            strokeDasharray={entry.value.includes('(Likes)') ? '5 5' : 'none'} 
                        />
                    </svg>
                    <span style={{ color: 'black', marginLeft: '5px' }}>{entry.value}</span>
                </li>
            ))}
        </ul>
        );
      };

      const dropdownRef = useRef(null);

      useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        }
      
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    useEffect(() => {
        
        const fetchChartData = async () => {
            const result = await axios(
                'https://mitoworld.io/world-view-and-like-statistic/?user_id=' + userData.userId,
            );
            // Get the range of dates in the data
            const dates = result.data.flatMap(item => item.data.map(dateTime => new Date(dateTime).toISOString().split('T')[0]));
            const minDate = new Date(Math.min.apply(null, dates.map(date => new Date(date))));
            const maxDate = new Date(Math.max.apply(null, dates.map(date => new Date(date))));
            // Initialize the data object with an object for each date in the range
            const data = {};
            for (let date = new Date(minDate); date <= maxDate; date.setDate(date.getDate() + 1)) {
                const dateString = date.toISOString().split('T')[0];
                data[dateString] = { name: dateString };
                result.data.forEach(item => {
                    data[dateString][`${item.world_name} (Views)`] = 0;
                    data[dateString][`${item.world_name} (Likes)`] = 0;
                });
            }

            // Populate the data object with the counts of views and likes
            result.data.forEach(item => {
                item.data.forEach(dateTime => {
                    const date = new Date(dateTime).toISOString().split('T')[0];
                    if (item.type === 'views') {
                        data[date][`${item.world_name} (Views)`] += 1;
                    } else if (item.type === 'likes') {
                        data[date][`${item.world_name} (Likes)`] += 1;
                    }
                });
            });

            const sortedData = Object.values(data).sort((a, b) => new Date(a.name) - new Date(b.name));
            setChartData(sortedData);
        };

        const fetchTableData = async () => {
            const formData = new FormData();
            formData.append('world_id', '0');
            formData.append('user_id', userData.userId);
            formData.append('include_json', '0');
            console.log(userData.userId);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/world-data/search`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });

            let data = await response.json();

            // Convert 'views' data to numbers
            data = data.map(item => ({
                ...item,
                views: Number(item.views)
            }));

            data = data.slice(Math.max(data.length - 5, 0)); // Limit to last 5 items
            setTableData(data);
        };

        fetchChartData();
        fetchTableData();
        }, []);


    useEffect(() => {
        if (sortConfig !== null) {
            let sortedData = [...tableData];
            sortedData.sort((a, b) => {
                if (sortConfig.direction === 'ascending') {
                    return a[sortConfig.key] < b[sortConfig.key] ? -1 : (a[sortConfig.key] > b[sortConfig.key] ? 1 : 0);
                } else {
                    return a[sortConfig.key] > b[sortConfig.key] ? -1 : (a[sortConfig.key] < b[sortConfig.key] ? 1 : 0);
                }
            });
            setTableData(sortedData);
        }
    }, [sortConfig]);
    const worldNames = Array.from(new Set(Object.keys(chartData[0] || {}).filter(key => key !== 'name').map(key => key.split(' ')[0])));
    
    const colors = ['#FF595E', '#FFCA3A', '#8AC926', '#1982C4', '#6A4C93'];
    let colorIndex = 0;

    const getNextColor = () => {
        const color = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length;
        return color;
    };

    const worldColorMap = worldNames.reduce((acc, world) => ({ ...acc, [world]: getNextColor() }), {});
    return (
        <Layout pageTitle="World Analytics" userData={userData}>
            <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
                <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-5 py-6">
                    {/* Breadcrumb & Search */}
                    {/* Content */}
                    <div className="rounded-2xl mb-6" style={{ backgroundColor: '#f8fafc'}}>
                        <div className="flex">
                            <div className=" p-5"  style={{  width: "75%" }}>
                                <ResponsiveContainer width="100%" height={400}>
                                    <LineChart
                                        data={chartData.slice(Math.max(chartData.length - 7, 0))}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="6 6" />
                                        <XAxis
                                            dataKey="name"
                                            tickCount={12}
                                            tick={{ fontSize: 13 }}
                                            tickFormatter={(str) => {
                                                const date = new Date(str);
                                                return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
                                            }}
                                        />
                                        <YAxis />
                                        <Tooltip 
                                            content={({ payload, label }) => {
                                                const viewsPayloads = payload?.filter(p => p.dataKey.includes('(Views)'));
                                                const date = new Date(label);
                                                const formattedLabel = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
                                                return (
                                                    <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid black' }}>
                                                        <p style={{ fontSize: 16 }}>{formattedLabel}</p>
                                                        {viewsPayloads.map(p => <p key={p.dataKey} style={{ fontSize: 16 }}>{p.dataKey}: {p.value}</p>)}
                                                    </div>
                                                );
                                            }} 
                                        />                    
                                        <Legend content={<CustomLegend />} layout="vertical" align="center" verticalAlign="top" />
                                        {Object.keys(chartData[0] || {}).filter(key => key !== 'name').slice(0, 10).map((key, index) => (
                                            <Line 
                                                key={key} 
                                                type="linear" 
                                                dataKey={key} 
                                                stroke={worldColorMap[key.split(' ')[0]]} 
                                                strokeDasharray={key.includes('(Likes)') ? '5 5' : 'none'} 
                                                activeDot={{ r: 8 }} 
                                            />
                                        ))}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="p-10" style={{ display: 'flex', flexDirection: 'column', width: "25%" }}>       
                                <div className="dropdownAnchor">
                                    <button onClick={toggleDropdown}>Select options</button>
                                </div>
                                {isOpen && (
                                    <div ref={dropdownRef} className="dropdownMenu" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                    {options.map((option) => (
                                        <div key={option}>
                                        <label>
                                            <input
                                            type="checkbox"
                                            checked={selectedOptions.includes(option)}
                                            onChange={() => toggleOption(option)}
                                            />
                                            {option}
                                        </label>
                                        </div>
                                    ))}
                                    </div>
                                )}
                                <div style={{ width: '100%', marginTop: 'auto' }}>
                                    <img src="https://mitoworld.io/wp-content/uploads/2024/03/legend.svg" alt="Legend" />
                                </div>
                            </div>
                            
                        </div>

                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => sortData('world_name')} style={{ textAlign: 'left', paddingLeft: "50px" }}>
                                    World Name 
                                    <span style={{ marginLeft: '10px' }}>
                                        {(sortConfig.key === 'world_name' && sortConfig.direction === 'ascending') ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                                    </span>
                                </th>
                                <th style={{ textAlign: 'left' }} onClick={() => sortData('likes')}>
                                    Likes 
                                    <span style={{ marginLeft: '10px' }}>
                                        {(sortConfig.key === 'likes' && sortConfig.direction === 'ascending') ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                                    </span>
                                </th>
                                <th style={{ textAlign: 'left' }} onClick={() => sortData('views')}>
                                    Views
                                    <span style={{ marginLeft: '10px' }}>
                                        {(sortConfig.key === 'views' && sortConfig.direction === 'ascending') ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                                    </span>
                                </th>
                                <th style={{ textAlign: 'left' }} onClick={() => sortData('created_date_time')}>
                                    Date Created
                                    <span style={{ marginLeft: '10px' }}>
                                        {(sortConfig.key === 'created_date_time' && sortConfig.direction === 'ascending') ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                                    </span>
                                </th>
                                <th>
                                    <a href="/world-analytics/more-info" style={{ color: '#155A9F', textDecoration: 'none' }}>
                                        View More
                                        <FontAwesomeIcon icon={faChevronRight} style={{ color: '#155A9F', marginLeft: '10px' }} />
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, index) => {
                                const date = new Date(item.created_date_time);
                                const formattedDate = date.toLocaleDateString('en-GB', {
                                    day: 'numeric', 
                                    month: 'short', 
                                    year: 'numeric'
                                });

                                return (
                                    <tr key={index} style={{ borderTop: "1px solid #CBD5E1", height: "80px"}}>
                                        <td >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img 
                                                     src={item.world_pic || "https://d3ae72ibwgviyf.cloudfront.net/250x250/static-assets/blueprint_static.png"} 
                                                     alt={item.world_name} 
                                                     onError={(e) => {
                                                         if (!e.target.src) {
                                                           e.target.onerror = null; 
                                                           e.target.src="https://d3ae72ibwgviyf.cloudfront.net/250x250/static-assets/blueprint_static.png";
                                                         }
                                                       }}
                                                    style={{ 
                                                        height: '50px', 
                                                        width: 'auto', 
                                                        borderRadius: "50%", 
                                                        display: 'flex', 
                                                        alignItems: 'center',
                                                        border: "1px solid #CBD5E1",
                                                        marginRight: '30px', 
                                                        marginLeft: "50px"
                                                    }}
                                                />
                                                {item.world_name}
                                            </div>
                                        </td>
                                        <td>{item.like_count}</td>
                                        <td>{item.visit_count}</td>
                                        <td>{formattedDate}</td>
                                        <td></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
};

export default WorldAnalytics;