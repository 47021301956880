import moment from "moment";
import axios from "axios";
import {Link, useNavigate } from "react-router-dom";


const Subscriptions = ({
  subscription_name,
  subscription_type,
  subscription_start,
  subscription_end,
  subscription_stop,
  mitopro_feature,
  world_id,
  user_id,
  payment_date,
  payment_amount,
  ref_no,
  reference_id,
  stripe_id,
  status,
  textColor,
  actionColor,
  actionText,
  text,
  world_name,
  world_pic,
  user_email,
  jwt,
  session_url,
  world_details,
  payment_method

}) => {
  const navigate = useNavigate();

  if (status == 0) {
    textColor = "#000";
    text = "Draft";
  } else if (status == 1) {
    textColor = "#10B981";
    text = "Published";
  } else {
    textColor = "#F59E0B";
    text = "View-only";
  }

  const currentTime = new Date().getTime();
  const subscriptionEndTime = new Date(subscription_end).getTime();

  if (reference_id !== null && payment_method == 'stripe' && subscription_stop == null && currentTime < subscriptionEndTime)  {
    actionColor = "#155A9F";
    actionText = "Manage Plan"
  } else if (reference_id !== null && payment_method == 'stripe' &&  status == 2)  {
    actionColor = "#10B981";
    actionText = "Renew Plan"
  } else if (mitopro_feature == 1 && subscription_type == 'trial'){
    actionColor = "#10B981";
    actionText = "Upgrade Plan"
  }

  if(subscription_type == 'trial') {
    subscription_type = 'Free Trial'
  }else if (subscription_type == 'month') {
    subscription_type = 'Monthly'
  } else if (subscription_type == 'year') {
    subscription_type = 'Yearly'
  }

  async function upgradeWorld() {

    if (reference_id !== null && payment_method == 'stripe' && subscription_stop == null && currentTime < subscriptionEndTime)  {
      window.location.replace(session_url);
    } else if (reference_id !== null && payment_method == 'stripe' &&  status == 2) {

      navigate("/worlds/renew", {
        state: {
          worldId : world_id
        },
      });
    } else if (mitopro_feature == 1 && subscription_type == 'trial'){
      var  exp = Math.floor(new Date().getTime() + 6000 / 1000);

      let body = new FormData();
      
      body.append("type", 'upgrade');
      body.append("package_type", 'monthly_mitopro');
      body.append("world_value", world_id);
      body.append("user_id", user_id);
      body.append("user_email", user_email);
      body.append("cancel_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-fail-payment`);
      body.append("success_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-success-payment`);


        await axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/stripe/create_stripe`,
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          console.log("Upgrading world", world_id);
          window.location.replace(response.data);

          // navigate("/subscription/payment_status");
        })
        .catch((err) => {
          console.error("err", err);
        });
    }
  }


  return (
    <tr className="border-b border-grey-300">
      <td className="pl-8 flex items-center font-medium text-sm">
        {" "}
        <img
          src={world_pic}
          className="max-w-[60px] mr-3 my-3 rounded-full"
          alt=""
        />{" "}
        {world_name}
      </td>
      <td className={"text-sm font-medium text-[" + textColor + "] px-2"}>
        {text}
      </td>
      <td className="text-sm font-medium capitalize px-2">
        {subscription_type}
      </td>
      <td className="text-sm font-medium px-2">
        {subscription_start ? moment(subscription_start).format("DD MMM YYYY") : '-'}
      </td>
      <td
        className={
          "text-sm font-medium px-2 " +
          (subscription_stop == null
            ? ""
            : "text-[#94A3B8]")
        }
      >
        {subscription_stop !== null && subscription_end
          ? 'Cancelled'
          : subscriptionEndTime > currentTime 
          ? moment(subscription_end).format("DD MMM YYYY")
          : subscription_end
          ? 'Expired'
          : '-'
          }
      </td>
      <td className="text-sm font-medium">
      <Link
          to={`/worlds/${world_id}`}
          state={{ worldDetails: world_details }}
        >
        <button className="rounded-3xl font-medium px-4 py-2 mb-2 bg-[#155A9F] hover:bg-hover-primary-btn transition ease-in-out duration-300 text-white text-sm">
          Manage World
        </button>{" "}
        </Link>
       
        <button className={"ml-4 text-[" + actionColor + "] text-sm font-medium"} onClick={() => upgradeWorld()}>
          {actionText}
        </button>
        
      
      
      </td>
    </tr>
  );
};

export default Subscriptions;
