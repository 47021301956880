import React, { useState, useRef, useEffect } from "react";
// import "./src/index.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import LogoutButton from "./LogoutButton";
import LoginButton from "./LoginButton";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaArrowUp } from 'react-icons/fa';


const Layout = ({
  children,
  pageTitle,
  navigateBack,
  handleNotification,
  userData,
}) => {

  const {
    user,
    getIdTokenClaims,
  } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();
  let menuRef = useRef();
  let buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState();
  const [notification, setNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState();
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [defaultProfilePicture,   setDefaultProfilePicture] = useState("");

   useEffect(() => { 
    getUserData();
    setUserId(userData.user_id);
    setUsername(userData.username);
    setProfilePicture(userData.profilePicture);
    setDefaultProfilePicture(userData.defaultProfilePicture);
  }, []);

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  });

  useEffect(() => {
    let url = location.pathname.split("/")[1];
    setCurrentPath(url);
  }, [location]);

  useEffect(() => {
    if (!handleNotification == "") {
      setNotification(true);
      setNotificationText(handleNotification);
    }
  }, [handleNotification]);

  useEffect(() => {
    if (notification == true) {
      const timer = setTimeout(() => {
        setNotification(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  function goBack() {
    navigate(-1);
  }

  async function getUserData() {
    const token = await getIdTokenClaims();

    let body = new FormData();
    body.append("id_token", token.__raw);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/login`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // make name uppercase
        const str = response.data.name;
        const arr = str.split(" ");

        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const str2 = arr.join(" ");
        setUserId(response.data.user_id);
        setUsername(str2);
        setProfilePicture(response.data.profile_pic);
        setDefaultProfilePicture(user.picture);
        setEmail(response.data.email);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }

  return (
    <div className="flex flex-row bg-special relative lg:overflow-y-auto justify-end">
      <div
        ref={menuRef}
        className={
          "min-w-[250px]  lg:w-[20%] absolute lg:fixed h-full min-h-screen flex flex-col space-between top-0 items-center sidebar inset-y-0 left-0 transform lg:translate-x-0 transition duration-300 ease-in-out duration-300 z-10 bg-[#0F172A] " +
          (open ? "sticky " : "-translate-x-full")
        }
      >
        <Link to="/">
          <img
            src={require("../img/mito-logo.png")}
            alt="logo"
            className="mx-auto mt-10 scale-100"
          />
        </Link>

        {/* List div */}
        <div className="flex flex-col px-4 text-white mt-12 text-left w-full">
          <Link to="/">
            <div
              className={`flex flex-row items-center transition ease-in-out duration-300 hover:bg-[#1E293B] rounded-lg group`}
            >
              <span
                className={`text-[#64748B] transition ease-in-out duration-300 group-hover:text-[#ffffff] font-medium px-4 py-4 ${
                  currentPath == "" && " text-[#ffffff]"
                }`}
              >
                Dashboard
              </span>
              {currentPath == "" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <Link to="/worlds">
            <div
              className={`flex flex-row items-center transition ease-in-out duration-300 hover:bg-[#1E293B] rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "worlds" && " text-[#ffffff]"
                }`}
              >
                My Worlds
              </span>
              {currentPath == "worlds" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <Link to="/world-analytics">
            <div
              className={`flex flex-row items-center hover:bg-[#1E293B] transition ease-in-out duration-300 rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "world-analytics" && " text-[#ffffff]"
                }`}
              >
                World Analytics 
              </span>
              {currentPath == "world-analytics" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <Link to="/subscription">
            <div
              className={`flex flex-row items-center hover:bg-[#1E293B] transition ease-in-out duration-300 rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "subscription" && " text-[#ffffff]"
                }`}
              >
                Manage Subscriptions
              </span>
              {currentPath == "subscription" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <hr className="border-[#64748B] my-3" />
          <Link to="/explore">
            <div
              className={`flex flex-row items-center hover:bg-[#1E293B] transition ease-in-out duration-300 rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "explore" && " text-[#ffffff]"
                }`}
              >
                Explore Worlds
              </span>
              {currentPath == "explore" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <Link to="/customize-avatar">
            <div
              className={`flex flex-row items-center hover:bg-[#1E293B] transition ease-in-out duration-300 rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "customize-avatar" && " text-[#ffffff]"
                }`}
              >
                My Avatar
              </span>
              <FaArrowUp color="#64748B" style={{ transform: "rotate(45deg)" }} />
              {currentPath == "customize-avatar" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>
          <hr className="border-[#64748B] my-3" />
          <Link to="https://docs.mitoworld.io/mitoworld-guide/mitoworld-guide-1/quick-start-guide" target="_blank">
            <div
              className={`flex flex-row items-center hover:bg-[#1E293B] transition ease-in-out duration-300 rounded-lg group`}
            >
              <span
                className={`text-[#64748B] group-hover:text-[#ffffff] transition ease-in-out duration-300 font-medium px-4 py-4 ${
                  currentPath == "docs" && " text-[#ffffff]"
                }`}
              >
                Quick Start Guide 
              </span>
              <FaArrowUp color="#64748B" style={{ transform: "rotate(45deg)" }} />
              {currentPath == "docs" && (
                <span>
                  <div className="w-1.5 h-1.5 rounded-full bg-white" />
                </span>
              )}
            </div>
          </Link>

        </div>
        {/* Powered by section */}
        <img
          src={require("../img/vtf.png")}
          className="scale-100 mx-auto mt-auto mb-[5vh]"
          alt=""
        />
        {/* Footer Section */}
        <div className="bg-[#1E293B] sticky inset-x-0 bottom-0 text-white py-5 w-full">
          <p className="text-center mb-4">v1.0</p>
          <div className="flex justify-center">
            {/* <p className="text-center mx-2 min-w-[81.35px]">Contact Us</p> */}
            <p onClick={() =>  window.open('https://mitoworld.io/homepage/contact-us/') } className="text-center mx-2 min-w-[81.35px] cursor-pointer">Contact Us</p>
            <p onClick={() =>  window.open('https://docs.mitoworld.io/mitoworld-guide/?_ga=2.260340943.359329789.1681094345-1812927919.1676860168&_gl=1*u3kyf3*_ga*MTgxMjkyNzkxOS4xNjc2ODYwMTY4*_ga_6DDEYBDWFW*MTY4MTExODU4Ny4yNC4xLjE2ODExMTkxODMuMC4wLjA.') }  className="text-center mx-2 min-w-[81.35px] cursor-pointer">Support</p>

          </div>
        </div>
      </div>
      <div className="lg:w-[80%] h-full min-h-[100vh] bg-[#F1F4F7] rounded-l-[30px] z-10 col-span-9">
        {/* Dashboard and profile icon */}
        <div className="flex justify-between mx-14 mt-12 pl-10">
          <div className="flex items-center gap-5">
            {navigateBack && (
              <button onClick={() => goBack()}>
                <Unicons.UilAngleLeft className="inline" size="30" />
              </button>
            )}

            <p className="text-2xl font-medium">{pageTitle}</p>
          </div>
          <div className="flex items-center">
            <div className="group relative z-10">
              <button className="text-sm mr- font-medium">
                {" "}
                Hi, {username}
                {profilePicture || defaultProfilePicture ? (
                  <img
                    src={ profilePicture ? profilePicture : defaultProfilePicture}
                    className="inline h-8 w-8 object-center ml-2 object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                    alt=""
                  />
                ) : (
                  <Unicons.UilUser className="inline" />
                )}{" "}
              </button>
              {/* <button className="bg-gray-800 text-white px-6 h-10 rounded">Menü</button> */}
              <nav
                tabIndex="0"
                className="p-4 shadow-md bg-white invisible rounded-xl w-60 absolute right-0 top-full transition ease-in-out duration-300 opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1"
              >
                <ul>
                  <li>
                    <Link to="/profile">
                      <a className="block py-3 px-1 rounded hover:bg-gray-100">
                        Profile
                      </a>
                    </Link>
                  </li>
                  {/* <li>
                    <a href="#" className="block py-3 px-1 rounded hover:bg-gray-100">
                      Account Integration
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="#" className="block py-3 px-1 rounded hover:bg-gray-100">
                      Invite a Friend
                    </a>
                  </li> */}
                  <li className="bg-gray-300 my-3 px-1 h-px">&nbsp;</li>
                  <li>
                    <a target="_blank" href="https://docs.mitoworld.io/mitoworld-guide/mitoworld-guide-1/quick-start-guide" className="block py-3 px-1 rounded hover:bg-gray-100">
                      Tutorial
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://docs.mitoworld.io/mitoworld-guide/terms-of-service/license-to-mitoworld-software" className="block py-3 px-1 rounded hover:bg-gray-100">
                      Terms and Service
                    </a>
                  </li>
                  <li>
                    <LogoutButton />
                  </li>
                </ul>
              </nav>
            </div>
            {/*  mobile menu button */}
            <button
              ref={buttonRef}
              onClick={() => setOpen(!open)}
              className="flex justify-between lg:hidden mobile-menu-button ml-4 focus:outline-none"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
        {children}
        {/* Notification */}
       
        <div className={`fixed bottom-[20px] transition-all ease-in duration-300 ${notification ? 'opacity-100' : "opacity-0 bottom-[-35px]"} `}>
          <div className=" bg-black ml-5 py-3 rounded flex flex-row justify-center items-center gap-5 px-2">
            <img
              src={require("../img/assets/success.png")}
              alt="logo"
              className="w-[24px] mx-3"
            />
            <p className="font-medium text-md text-white">
              {notificationText}
            </p>
            <div
              className="ml-2 mr-2 rounded-full hover:cursor-pointer"
              onClick={() => setNotification(false)}
            >
              <Unicons.UilMultiply className="flex text-white" size={20} />
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Layout;
