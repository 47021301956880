import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const clearJWT = function () {
    localStorage.removeItem("jwt");
    localStorage.removeItem("userid");
    logout({ logoutParams: { returnTo: `${process.env.REACT_APP_DEVELOPMENT_URL}/login` } });
  };

  return (
    <button
      className="block mt-2 font-medium rounded text-center text-[#B91C1C] w-full py-3 px-1 bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300"
      onClick={() => clearJWT()}
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
